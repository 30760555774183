<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="短剧名称" prop="videoId" >
        <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
        <a-select
            v-model="form.videoId"
            showSearch
            style="width: 100%"
            placeholder="请选择短剧"
            allow-clear
            optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.videoName" v-for="(item,index) in listAll">
            {{item.videoName}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="视频" prop="videoUrl" >
        <!--        <a-input v-model="form.videoUrl" placeholder="请输入短剧url" />-->
        <a-upload
            listType="picture-card"
            class="avatar-uploader"
            v-model="form.videoUrl"
            action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"
            :showUploadList="false"
            :beforeUpload="beforeUploadVideo"
            :customRequest="handleChange"
        >
          <video :src="form.videoUrl" v-if="form.videoUrl" width="300" height="150" controls>

          </video>
          <div v-if="form.videoUrl" class="ant-upload-text">更换图片</div>

          <!--          <img v-if="form.videoUrl" :src="form.videoUrl" alt="logo"-->
          <!--               style="height:104px;max-width:300px"/>-->
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>

            <div class="ant-upload-text">上传</div>

            <a-progress v-if="percent>0" :percent="percent" />
          </div>
          <!--          <a-spin v-if="uploadLoading" />-->
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="seq" >
        <a-input v-model="form.seq" placeholder="请输入排序" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPublicize, addPublicize, updatePublicize } from '@/api/video/publicize'
import {listVideo} from "@/api/video/video";
import UploadFileToVOD from "@/api/vod";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      listAll:[],
      submitLoading: false,
      uploadLoading: false,
      percent: 0,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        videoId: null,

        videoUrl: null,

        seq: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        videoId: [
          { required: true, message: '短剧id不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getGather();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    beforeUploadVideo:function (file) {
      var fileType = file.type;
      if (fileType.indexOf('video') < 0) {
        this.$message.warning('请上传视频');
        this.form.videoUrl = '';
        return false
      }

      //TODO 驗證文件大小
    },

    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      let that = this
      that.form.videoUrl = ""
      new UploadFileToVOD([info.file], {
        success: function (fileName) {

          that.form.videoUrl = fileName[0];//文件名
          that.uploadLoading = false;
        },
        vProgress:function (val) {
          console.log(val)
          that.percent=val*100

        }
      });
      that.uploadLoading = false;
      this.percent=0


    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    getGather() {
      listVideo().then(res => {
        this.listAll = res.data;
      })
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        videoId: null,
        videoUrl: null,
        seq: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.form = {
        id: null,

            videoId: null,

            videoUrl: null,

            seq: null,

            createTime: null,

            remark: null,

      }
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPublicize({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePublicize(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPublicize(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
